// Here you can add other styles
.scrollable {
  max-height: 700px; /* you can change as you need it */
  overflow-y: auto; /* to get scroll */
  overflow-x:hidden;
}

.scrollableTerminal {
  max-height: 40vh; /* you can change as you need it */
  overflow-y: auto; /* to get scroll */
  overflow-x: hidden;
}
